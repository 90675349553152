
import { defineComponent, ref, reactive, watch } from "vue";
import useModel from "@/hooks/useModel";
import { getSetList } from "@/API/payment";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "App",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: String,
      default: "",
    },
    payoutRound: {
      type: String,
      default: "",
    }
  },
  setup(props, context) {
    const router = useRouter();
    const column = reactive([
      {
        title: "Payment Release Set",
        slots: { customRender: "set" },
      },
    ]);
    const _visible = useModel(props, "visible");
    const data = ref();
    watch(
      () => props.entity,
      (val) => {
        getSetList(val).then((res) => {
          data.value = res;
        });
      },
      {
        immediate: true,
      }
    );
    const handleSet = (record: any) => {
      const page = record.value.replace(/\s/g, "");
      console.log('entity.....' + props.entity);
      console.log('payoutrouhd.....' + props.payoutRound);
      if (page === "DCNTemplateSet") {
        router.push({ name: "DcnTemplateSet" ,
                      query: { entity: props.entity, payoutRound: props.payoutRound },
        });
      } else if (page === "VehicleNameSet") {
        router.push({
          path: "/paymentSales/vehicleNameSet",
          query: { entity: props.entity },
        });
      } else if (page === "VendorCodeSet") {
        router.push({ path: "/paymentSales/dealerVendorCodeSet" ,
                      query: { entity: props.entity, payoutRound: props.payoutRound }
        });
      } else if (page === "VoucherAttachmentSet") {
        router.push({ path: "/paymentSales/voucherAttachmentSet" });
      } else if (page === "UploadFile") {
        context.emit("on-upload-show");
      }
    };
    return {
      data,
      column,
      _visible,
      handleSet,
    };
  },
});
